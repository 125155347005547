<template>
  <div>
    <v-card color="#ffffffdd">
      <!-- <v-card-title primary-title>Login</v-card-title>
      <v-divider></v-divider>-->
      <v-card-text>
        <v-form class="pa-3" v-if="!reset">
          <v-text-field
            autofocus
            v-model="email"
            v-validate="'required|email'"
            :error-messages="errors.collect('email')"
            data-vv-name="email"
            required
            name="email"
            label="Email"
          ></v-text-field>
          <v-text-field
            v-model="password"
            v-validate="'required|min:6|max:10'"
            :error-messages="errors.collect('password')"
            @keypress.enter="loginUser"
            data-vv-name="password"
            required
            type="password"
            name="password"
            label="Password"
          ></v-text-field>
          <v-btn
            @click="loginUser"
            :disabled="!validateForm"
            depressed
            large
            block
            color="primary"
            class="mb-5"
          >Login</v-btn>
          <span class="d-flex flex-column align-end" @click="reset = true">Forgot Password?</span>
        </v-form>
        <v-form class="pa-3" v-else>
          <v-text-field v-model="email" autofocus label="Email" @keypress.enter="restPass()"></v-text-field>
          <v-btn @click="restPass()" block color="primary" class="mb-5">Reset Password</v-btn>
          <span class="d-flex flex-column align-end" @click="reset = false">Login with Password</span>
        </v-form>
      </v-card-text>
    </v-card>
    <Snackbar :snackbar="snackbar" />
  </div>
</template>

<script>
import firebase from "firebase";
import { db } from "@/db";

export default {
  $_veeValidate: {
    validator: "new"
  },
  data() {
    return {
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        mode: null, //vertical or multi-line or null
        timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      },
      email: "",
      password: "",
      reset: false,
      dictionary: {
        attributes: {
          email: "E-mail",
          password: "Password"
          // custom attributes
        },
        custom: {
          email: {
            required: () => "Please enter email",
            max: "The email field may not be greater than 10 characters"
            // custom messages
          },
          password: {
            required: "Please enter password"
          }
        }
      }
    };
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    }
  },
  methods: {
    loginUser() {
      this.$validator.validateAll().then(() => {
        // Login user using firebase
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(
            message => {
              this.snackbar.isActive = true;
              this.snackbar.color = "success";
              this.snackbar.text = "You are successfully login!";

              setTimeout(() => this.$router.replace("/"), 2000);
              // console.log('You are successfully logged!')
            },
            error => {
              // console.log("Error:" + error);
              this.snackbar.isActive = true;
              this.snackbar.color = "error";
              this.snackbar.text = error.message;
            }
          );
      });
    },
    restPass() {
      const auth = firebase.auth();
      const emailAddress = this.email;

      auth
        .sendPasswordResetEmail(emailAddress)
        .then(() => {
          this.snackbar.isActive = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Reset Email send to" + emailAddress;
          // Email sent.
        })
        .catch(error => {
          this.snackbar.isActive = true;
          this.snackbar.color = "error";
          this.snackbar.text = error.message;
          // An error happened.
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        next("/");
        //window.location.href = "/"
      } else {
        next();
      }
    });
  }
};
</script>

<style></style>
