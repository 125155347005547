<template>
  <div>
    <v-img
      :src="require('@/assets/clients/media/banner/register-background.jpg')"
      height="600"
      cover
    >
      <v-container class="fill-height">
        <v-row align="center" justify="end">
          <v-col cols="12" md="4">
            <v-card>
              <v-tabs v-model="tab" centered grow icons-and-text>
                <v-tabs-slider></v-tabs-slider>
                <v-tab exact to="login">
                  Login
                  <v-icon>mdi-login</v-icon>
                </v-tab>
                <v-tab exact to="#register">
                  Register
                  <v-icon>mdi-account-plus-outline</v-icon>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item value="login">
                  <Login />
                </v-tab-item>
                <v-tab-item value="register">
                  <Register />
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
import Login from "./components/Login";
import Register from "./components/Register";

export default {
  components: {
    Register,
    Login
  },
  data() {
    return {
      tab: null
    };
  }
};
</script>

<style></style>
