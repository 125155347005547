<template>
  <div>
    <v-card color="#ffffffdd">
      <!-- <v-card-title primary-title>Register</v-card-title>
      <v-divider></v-divider>-->
      <v-card-text>
        <v-form>
          <v-text-field
            autofocus
            v-model="name"
            v-validate="'required'"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            required
            @keypress.enter="registerUser"
            name="name"
            label="Name"
          ></v-text-field>
          <v-text-field
            v-model="email"
            v-validate="'required|email'"
            :error-messages="errors.collect('email')"
            data-vv-name="email"
            required
            @keypress.enter="registerUser"
            name="email"
            label="Email"
          ></v-text-field>
          <v-text-field
            v-model="phone"
            v-validate="'required'"
            :error-messages="errors.collect('phone')"
            data-vv-name="phone"
            required
            @keypress.enter="registerUser"
            name="phone"
            label="Phone"
          ></v-text-field>
          <v-text-field
            v-model="password"
            v-validate="'required|min:6|max:10'"
            :error-messages="errors.collect('password')"
            data-vv-name="password"
            required
            @keypress.enter="registerUser"
            name="password"
            type="password"
            label="Password"
          ></v-text-field>

          <v-btn
            block
            large
            color="success"
            :disabled="!validateForm"
            @click="registerUser"
          >Register</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <Snackbar :snackbar="snackbar" />
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import { db } from "@/db";
import { setTimeout } from "timers";

export default {
  data() {
    return {
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        // mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      },
      name: "",
      email: "",
      phone: "",
      password: ""
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.name != "" &&
        this.email != "" &&
        this.phone != "" &&
        this.password != ""
      );
    }
  },
  methods: {
    registerUser() {
      if (!this.validateForm) return false;
      // create user using firebase
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(
          () => {
            let user = firebase.auth().currentUser;
            let userId = user.uid;
            // console.log(userId);
            let userInfo = {
              email: this.email,
              role: "user",
              name: this.name,
              phone: this.phone,
              photoURL: "img/profile.jpg"
            };
            db.collection("users")
              .doc(userId)
              .set(userInfo);
            user
              .updateProfile({
                displayName: this.name,
                photoURL: "img/profile.jpg"
              })
              .then(() => {
                // Update successful.
                // console.log('Update profile successful')
                // this.snackbar.isActive = true;
                // this.snackbar.color = "success";
                // this.snackbar.text = "Update profile successfully!";
                setTimeout(() => this.$router.replace("/"), 2000);
              })
              .catch(error => {
                // An error happened.
                this.snackbar.isActive = true;
                this.snackbar.color = "error";
                this.snackbar.text = error.message;
                // console.log('An error happened on updatting profile ')
              });
            // console.log('You are successfully registered!')
            this.snackbar.isActive = true;
            this.snackbar.color = "success";
            this.snackbar.text = "You are successfully registered!";
          },
          error => {
            this.snackbar.isActive = true;
            this.snackbar.color = "error";
            this.snackbar.text = error.message;
            // console.log('New User Registration Failed ')
          }
        );
    }
  },
  beforeRouteEnter(to, from, next) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        window.location.href = "/";
      } else {
        next();
      }
    });
  }
};
</script>

<style></style>
